import React from "react"
import ReactPlayer from "react-player"

const VideoPlayer = ({
  url = "https://www.youtube.com/embed/rs-SUb2vWOU",
  caption = null,
}) => {
  return (
    <div className={`w-full mt-10 max-w-5xl `}>
      <div className={`relative `} style={{ paddingTop: "56.25%" }}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          className={`absolute top-0 left-0 `}
          controls={true}
        />
      </div>
      <div className="text-right italic text-gray-400 mt-1 text-xs md:text-sm">
        {caption}
      </div>
    </div>
  )
}

export default VideoPlayer
